import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Box, Flex, Text, theme } from "@nulogy/components";
import { COPY_WIDTH } from "../../components/CONSTANTS";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Spacing`}</h1>
    <p>{`A spacing scale is used to maintain consistent paddings between and within elements throughout our products. Sticking to a scale allows us to be more consistent and predictable, and makes our designs more harmonious.`}</p>
    <h2>{`Scale`}</h2>
    <p>{`Nulogy uses a scale based on `}<strong parentName="p">{`8px`}</strong>{` with modifiers from half (4px) to 8x (64px).`}</p>
    <Flex maxWidth={COPY_WIDTH} m="0 auto" justifyContent="space-between" mdxType="Flex">
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.half} width={theme.space.half} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.half}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      half
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x1} width={theme.space.x1} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x1}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x1
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x2} width={theme.space.x2} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x2}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x2
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x3} width={theme.space.x3} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x3}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x3
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x4} width={theme.space.x4} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x4}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x4
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x5} width={theme.space.x5} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x5}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x5
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x6} width={theme.space.x6} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x6}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x6
    </Text>
  </Box>
  <Box mdxType="Box">
    <Flex height="64px" verticalAlign="bottom" alignItems="flex-end" mdxType="Flex">
      <Box bg="lightBlue" mx="auto" mb="x2" height={theme.space.x8} width={theme.space.x8} mdxType="Box" />
    </Flex>
    <Text textAlign="center" fontSize={{
          extraSmall: "small",
          medium: "medium"
        }} mb="half" mdxType="Text">
      {theme.space.x8}
    </Text>
    <Text textAlign="center" fontSize="small" mdxType="Text">
      x8
    </Text>
  </Box>
    </Flex>
    <h2>{`Example`}</h2>
    <box {...{
      "maxwidth": "{COPY_WIDTH}",
      "m": "0 auto"
    }}>{`
  `}<span parentName="box" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "624px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2c3740af1a1bda340aec40e64c403a73/39c09/spacing-anatomy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.09375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA3ElEQVQoz32S6w7CIAyFef/3M+pfb4kZzl3ktgLHlI0FlUly1q2HtB+sIsaIUrw4hvCZL8Xeli9QWbnw/xVR2yVyR/KhIAhQdoJxBOsoxVJ68aqE/HDk8TLTQhYxagfZazSdQ9PbpHbknIXs59y9ValxPn5WOjJ3u8pxxR6USxRnGbC/EI43wqnxOFwJuzNBDgHtYKr3KJhImWkuuCSNJTSdwnM0qx6DSUXmyPR6oQofEuX9l+9sbv2cVGjDS4S1sWEa78P6XfraErqX/fF+xqYsiK8G66hszG0mfAMAfsVySIpceQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "spacing anatomy",
            "title": "spacing anatomy",
            "src": "/static/2c3740af1a1bda340aec40e64c403a73/39c09/spacing-anatomy.png",
            "srcSet": ["/static/2c3740af1a1bda340aec40e64c403a73/6f3f2/spacing-anatomy.png 256w", "/static/2c3740af1a1bda340aec40e64c403a73/01e7c/spacing-anatomy.png 512w", "/static/2c3740af1a1bda340aec40e64c403a73/39c09/spacing-anatomy.png 624w"],
            "sizes": "(max-width: 624px) 100vw, 624px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>
    </box>
    <h2>{`How to choose spacing`}</h2>
    <p>{`There are two important factors to consider when choosing spacing: size and relatedness`}</p>
    <ul>
      <li parentName="ul">{`Use less spacing inside smaller elements or between functionally related elements.`}</li>
      <li parentName="ul">{`Use more spacing inside larger elements or between less functionally related elements`}</li>
    </ul>
    <p><em parentName="p">{`Note: half should mostly be used for spacing related items within an element, e.g a button’s text and it’s icon.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      